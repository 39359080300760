@import '../../../../scss/theme-bootstrap';

.remain-on-aveda {
  text-align: center;
  padding: 30px 0;
  &__overlay-text {
    font-size: 28px;
    @include breakpoint($landscape-up) {
      font-size: 36px;
    }
  }
  &__overlay-cta {
    font-size: 15px;
    margin: 30px 0 20px;
  }
  &__overlay-link {
    font-size: 15px;
    cursor: pointer;
  }
}
